import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import { FaEdit, FaRetweet } from 'react-icons/fa';
import './LastDocketEntry.css';
import actionIcon from '@icons/more-line.svg';
import DataTable from '@Shared/DataTable/DataTable';
import { lastDocketEnteryData } from './lastDocketEnteryData';

const LastDocketEntry = () => {
  const headings = ['Date', 'Entered', 'Filing', 'Action'];

  const handleEdit = (entry) => {
    console.log('Edit:', entry);
  };

  const handleReschedule = (entry) => {
    console.log('Reschedule:', entry);
  };

  const renderRow = (entry) => (
    <>
      <td>{entry.date}</td>
      <td>{entry.entered}</td>
      <td>{entry.filing}</td>
      <td>
        <Menu>
          <MenuButton
            as={Button}
            p={0}
            variant="outline"
            border="none"
            backgroundColor="transparent"
            className="menu-button"
          >
            <img className="action-icon" src={actionIcon} alt="View" />
          </MenuButton>
          <MenuList className="custom-menu-list">
            <MenuItem
              icon={<FaEdit />}
              onClick={() => handleEdit(entry)}
              className="custom-menu-item"
            >
              Edit
            </MenuItem>
            <MenuItem
              icon={<FaRetweet />}
              onClick={() => handleReschedule(entry)}
              className="custom-menu-item"
            >
              Reassign
            </MenuItem>
          </MenuList>
        </Menu>
      </td>
    </>
  );

  return (
    <div className="last-docket-entry white-background rounded-border">
      <h3>Last Docket Entry</h3>
      <DataTable
        headings={headings}
        data={lastDocketEnteryData}
        renderRow={renderRow}
      />
    </div>
  );
};

export default LastDocketEntry;
