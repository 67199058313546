import { authTypes, userTypes } from '../types';
import * as api from '../../lib/apis/auth';
import users from '../../lib/mocks/users';

export const logIn = (params) => async (dispatch) => {

  // TODO: Replace when API is ready
  const user = users[0];
  dispatch({ type: authTypes.LOG_IN, payload: {token: "123"} });
  dispatch({ type: userTypes.SET_USER, payload: user });

  console.log('set auth and user for testing');

  // try {
  //   const token = await api.logIn(params);
  //   console.log('token:', token);
  //   dispatch({ type: authTypes.LOG_IN, payload: token });
  // } catch (error) {
  //   console.error('Error logging in:', error);
  // }
}

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: authTypes.LOG_OUT, payload: null });
  } catch (error) {
    console.error('Error logging out:', error);
  }
}
