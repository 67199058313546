const users = [
  {
    userId: 1,
    fName: "Alice",
    lName: "Johnson",
    address: "123 Main St, Springfield, IL",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 2,
    fName: "Bob",
    lName: "Smith",
    address: "456 Elm St, Rivertown, TX",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 3,
    fName: "Charlie",
    lName: "Brown",
    address: "789 Oak Ave, Hillcrest, CA",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 4,
    fName: "Dave",
    lName: "Williams",
    address: "101 Pine Rd, Lakeview, NY",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 5,
    fName: "Emily",
    lName: "Jones",
    address: "202 Maple St, Coastal, FL",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 6,
    fName: "Frank",
    lName: "Miller",
    address: "303 Birch Blvd, Greenfield, OH",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 7,
    fName: "Grace",
    lName: "Davis",
    address: "404 Cedar Ct, Brookse, CO",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 8,
    fName: "Henry",
    lName: "Wilson",
    address: "505 Spruce Dr, Meadowbrook, WA",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 9,
    fName: "Ivy",
    lName: "Taylor",
    address: "606 Fir St, Pleasantville, PA",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  },
  {
    userId: 10,
    fName: "Jack",
    lName: "Anderson",
    address: "707 Willow Way, Sunset Valley, AZ",
    userType: "userTypeMock",
    profileImage: "https://picsum.photos/200",
    apps: ["App1", "App2", "App3"]
  }
];

export default users;
