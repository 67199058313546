import React, { useState } from 'react';

import './PacerSearch.css';
import PacerSearchTextInput from './PacerSearchTextInput';
import PacerSearchSubmitButton from './PacerSearchSubmitButton';

const PacerSearchContainer = ({children, style, onFormDataChange}) => {
  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    console.log('formData:', formData);
    onFormDataChange({ ...formData, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('formData:', formData);
  }

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      onChange: handleChange,
      formData: formData,
    })
  );

  return (
      <form className="pacer-search-form" onSubmit={handleSubmit} style={style}>
        {childrenWithProps}
      </form>
  );
}

export default PacerSearchContainer;

