import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../../../../../assets/images/atticus/icons/arrow-right-green.svg';
import './PacerResultsTable.css';

const PacerResultsTable = ({ cases }) => {
  return (
    <div className="pacer-results-table">
      <ul>
        <li className="pacer-results-table-heading">
          <p>Case Name</p>
          <p>Court</p>
          <p>Case</p>
          <p># Docket Entries</p>  
          <p>Date Filed</p>
          <p>Date Closed</p>
          <p>Action</p>
        </li>
        {cases &&
          cases.map((caseItem, index) => (
            <li key={index} className="pacer-results-table-row">
              <p>{caseItem.caseName}</p>
              <p>{caseItem.court}</p>
              <p className="text-green-dark">
                <Link to={caseItem.absolute_url}>{caseItem.docketNumber}</Link>
              </p>
               <p>{caseItem.docketEntry}</p>
              <p>{new Date(caseItem.dateFiled).toLocaleDateString()}</p>
               <p>{new Date(caseItem.dateClosed).toLocaleDateString()}</p>
              <div className="pacer-results-actions-container">
                <Link to={`/dashboard/atticus/cases/${caseItem.docketNumber}`}>
                  <Arrow />
                </Link>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PacerResultsTable;
