import React from 'react';
import { useParams } from 'react-router-dom';
import './CaseOverview.css';
import Case from '../Case/Case';
import LastDocketEntry from '../LastDocketEntry/LastDocketEntry';
import CaseLegalTeam from '../CaseLegalTeam/CaseLegalTeam';

const CaseOverview = () => {
  const { id } = useParams();

  return (
    <main className="case-overview-page">
      <Case />
      <LastDocketEntry />
      <CaseLegalTeam />
    </main>
  );
};

export default CaseOverview;
