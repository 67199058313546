import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "./../../../assets/images/juristAi/jurist-logo-alone.png";
import AtticusLogo from "./../../../assets/images/atticus/logo/atticus-full-logo.svg";
import ArrowDown from "./../../../assets/images/atticus/icons/arrow-down-black.png";
import classNames from "classnames";
import "./NavBar.css";
import Button from "../Button/Button";
import menuIcon from "./../../../assets/images/atticus/icons/menu-outline.svg";
import closeIcon from "./../../../assets/images/atticus/icons/close-outline.svg";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="nav-container">
        <div className="nav-logo-container">
          <Link to={"https://juristai.org/ "} className="logo">
            <img src={Logo} alt="JuristAI" />
          </Link>
          <Link to={"/"} className="logo">
            <img src={AtticusLogo} alt="JuristAI" />
          </Link>
        </div>

        <nav className={`nav--list-container ${isMenuOpen ? "nav--open" : ""}`}>
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink exact="true" to="/" className={({ isActive }) => classNames("nav__link", { "nav__link--active": isActive })}>
                Home
              </NavLink>
            </li>
            <li className="nav__item ">
              <NavLink to="#" className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Meet Atticus
              </NavLink>
            </li>
            <li className="nav__item nav__item--dropdown">
              <span className="nav__link dropdown-link">
                <span className="pricing-dropdown-text"> Pricing </span> <img src={ArrowDown} className="arrow-icon" alt="Arrow Down" />
              </span>
              <ul className="dropdown">
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link borderd button-disabled", { "dropdown__link--active": false })}>
                    Private Attorneys
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link borderd button-disabled", { "dropdown__link--active": false })}>
                    Small Firms
                  </NavLink>
                </li>
                <li className="dropdown__item">
                  <NavLink to="#" className={({ isActive }) => classNames("dropdown__link button-disabled", { "dropdown__link--active": false })}>
                    Big Firms
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav__item ">
              <NavLink to="#" className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Book A Demo
              </NavLink>
            </li>
            <li className="nav__item ">
              <NavLink to="#" className={({ isActive }) => classNames("nav__link button-disabled", { "nav__link--active": false })}>
                Blog
              </NavLink>
            </li>
            <li className="nav__item nav__item--cta">
              <Button className="button--transparent button-disabled">Login</Button>
            </li>
            <li className="nav__item nav__item--cta">
              <Button className="button--primary button-disabled ">Sign Up</Button>
            </li>
          </ul>
        </nav>
        <button className="menu-toggle" onClick={toggleMenu}>
          <img className="menu-icon" src={isMenuOpen ? closeIcon : menuIcon} alt="Menu" />
        </button>
      </div>
    </header>
  );
};

export default NavBar;
