import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useRedirectIfNeeded = (selectedCase, setSelectedCase) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        const isCaseRelatedPage = pathname.includes('/dashboard/cases/') && (pathname.includes('/overview') || pathname.includes('/timeline') || pathname.includes('/upload'));
        if (!selectedCase && isCaseRelatedPage) {
            navigate('/dashboard/cases/');
        } else if (selectedCase && !isCaseRelatedPage) {

            setSelectedCase(null)

        }
    }, [selectedCase, pathname, navigate, setSelectedCase]);
};

export default useRedirectIfNeeded;
