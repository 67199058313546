import React, { useState, useRef, useEffect } from "react";
import "./CollapsibleCard.css";
import dropdownIcon from "./../../../assets/images/atticus/icons/arrow-dropdown.svg";

const CollapsibleCard = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(prev => !prev);
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0px';
            contentRef.current.style.opacity = isOpen ? '1' : '0';
        }
    }, [isOpen]);

    return (
        <div className="collapsible-card white-background rounded-border">
            <div className="collapsible-card-header" onClick={handleToggle}>
                <h4 className="collapsible-card-title">{title}</h4>
                <img
                    src={dropdownIcon}
                    alt="Toggle"
                    className={`dropdown-arrow ${isOpen ? "open" : ""}`}
                />
            </div>

            <div
                className={`collapsible-card-content ${isOpen ? "open" : "closed"}`}
                ref={contentRef}
            >
                {content}
            </div>
        </div>
    );
};

export default CollapsibleCard;
