import { authTypes } from '../types';

const initialState = null; //TODO: check for localStorage token

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOG_IN:
      return action.payload;
    case authTypes.LOG_OUT:
      return null;
    default:
      return state;
  }
}

export default authReducer;
