import React from 'react';
import PacerSearchContainer from '@Shared/PacerSearch/PacerSearchContainer';
import PacerSearchSubmitButton from '@Shared/PacerSearch/PacerSearchSubmitButton';
import PacerSearchTextInput from '@Shared/PacerSearch/PacerSearchTextInput';
import { ReactComponent as Arrow } from '@icons/arrow-right-black.svg';

const SearchSection = () => {
  return (
    <div className="dashboard-home-search">
      <PacerSearchContainer>
        <h2>Search Cases</h2>
        <PacerSearchTextInput name="query" placeholder="Search pacer..." />
        <PacerSearchSubmitButton>
          <p>Add a New Criminal Case</p>
          <Arrow className="pacer-search-submit-button-icon" />
        </PacerSearchSubmitButton>
      </PacerSearchContainer>
    </div>
  );
};
export default SearchSection;
