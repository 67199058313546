import React, { useState, useEffect } from 'react';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import PacerSearchContainer from '@Shared/PacerSearch/PacerSearchContainer';
import PacerSearchTextInput from '@Shared/PacerSearch/PacerSearchTextInput';
import PacerResultsTable from './PacerResultsTable/PacerResultsTable';
import { useLocation } from 'react-router-dom';
import searchCases from '@lib/mocks/searchCases';

const CasesSearch = () => {
  const [query, setQuery] = useState('');
  const [cases, setCases] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const initialQuery = location?.state?.query || '';
  const [pageTitle, setPageTitle] = useState(
    `Search Results for: ${initialQuery}`
  );

  const filterCases = (query) => {
    return searchCases.filter(
      (caseItem) =>
        caseItem.caseName.toLowerCase().includes(query.toLowerCase()) ||
        caseItem.court.toLowerCase().includes(query.toLowerCase()) ||
        caseItem.court_citation_string
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        caseItem.dateFiled.toLowerCase().includes(query.toLowerCase()) ||
        caseItem.docketNumber.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setTimeout(() => {
        const filteredCases = filterCases(initialQuery);
        setCases(filteredCases);
        setLoading(false);
        setHasSearched(true);
      }, 1000);
    };

    if (initialQuery) {
      fetchData();
    }
  }, [initialQuery]);

  const handleSearch = async () => {
    setLoading(true);
    setHasSearched(true);
    setPageTitle(`Search Results for: ${query}`);

    setTimeout(() => {
      const filteredCases = filterCases(query);
      setCases(filteredCases);
      setLoading(false);
    }, 1000);
  };

  const handleInputChange = (data) => {
    setQuery(data?.searchQuery);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <main className="dashboard-page case-search-page">
      <PageTitle title={pageTitle} subtitle=" " />
      <PageTitle
        subtitle={`Cases matching search: ${
          hasSearched ? cases?.length || 0 : 0
        }`}
      />
      <div onKeyDown={handleKeyDown}>
        <PacerSearchContainer onFormDataChange={handleInputChange}>
          <PacerSearchTextInput
            placeholder="Search pacer..."
            name="searchQuery"
          />
        </PacerSearchContainer>
      </div>
      {hasSearched && cases?.length > 0 && <PacerResultsTable cases={cases} />}
    </main>
  );
};

export default CasesSearch;
