import React, { useState } from 'react';
import './AddNewCase.css';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';
import DatePicker from '@Shared/DatePicker/DatePicker';

const AddNewCase = ({ onClose }) => {
  const [formData, setFormData] = useState({
    partyName: '',
    court: '',
    caseNumber: '',
    docketEntries: '',
    dateFiled: '',
    dateClosed: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleReset = (e) => {
    setFormData({
      partyName: '',
      court: '',
      caseNumber: '',
      docketEntries: '',
      dateFiled: '',
      dateClosed: '',
    });
    onClose();
  };
  return (
    <div className="add-case-modal">
      <div className="case-form-group">
        <label htmlFor="partyName">Party Name</label>
        <TextField
          placeholder="Party Name"
          name="partyName"
          value={formData.partyName}
          onChange={handleChange}
          className="case-form-group-input"
        />
      </div>
      <div className="case-form-group">
        <label htmlFor="court">Court</label>
        <TextField
          placeholder="Court"
          name="court"
          value={formData.court}
          onChange={handleChange}
          className="case-form-group-input"
        />
      </div>
      <div className="case-form-group">
        <label htmlFor="caseNumber">Case Number Full</label>
        <TextField
          placeholder="Case Number Full"
          name="caseNumber"
          value={formData.caseNumber}
          onChange={handleChange}
          className="case-form-group-input"
        />
      </div>
      <div className="case-form-group">
        <label htmlFor="docketEntries">Number of Docket Entries</label>
        <TextField
          placeholder="Number of Docket Entries"
          name="docketEntries"
          value={formData.docketEntries}
          onChange={handleChange}
          className="case-form-group-input"
        />
      </div>
      <div className="case-form-date-picker-group">
        <div className="case-form-group">
          <label htmlFor="dateFiled">Date Filed</label>
          <DatePicker
            placeholder="Date Filed"
            name="dateFiled"
            value={formData.dateFiled}
            onChange={(date) => setFormData({ ...formData, dateFiled: date })}
          />
        </div>
        <div className="case-form-group">
          <label htmlFor="dateClosed">Date Closed</label>
          <DatePicker
            placeholder="Date Closed"
            name="dateClosed"
            value={formData.dateClosed}
            onChange={(date) => setFormData({ ...formData, dateClosed: date })}
          />
        </div>
      </div>
      <Button className="button--primary button--full-width">Create</Button>
      <Button className="cancel-btn" onClick={handleReset}>
        Cancel
      </Button>
    </div>
  );
};

export default AddNewCase;
