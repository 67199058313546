import React from 'react';
import { useParams } from 'react-router-dom';
import './Case.css';
import CollapsibleCard from '@Shared/CollapsibleCard/CollapsibleCard';

const status = 'O';

const Case = () => {
  const { id } = useParams();
  const caseSummaryContent = (
    <p className="case-summary-description">
      Defendant is charged with conspiracy to defraud the United States,
      conspiracy to obstruct an official proceeding, obstruction of and attempt
      to obstruct an official proceeding, and conspiracy against rights. Charges
      stem from lleged efforts to overturn the 2020 presidential election
      results and his involvement in the January 6 Capitol attack. Given the
      gravity of the charges, the likelihood of the defendant's winning is low.
      Consider filing pre-trial motions to dismiss the charges based on
      constitutional arguments.
    </p>
  );

  return (
    <>
      <CollapsibleCard title="Case Summary" content={caseSummaryContent} />
      <div className="case-status-card white-background rounded-border">
        <div className="case-status-content-container">
          {' '}
          <div className="case-statis-label-text"> Case Status</div>{' '}
          <span
            className={`case-status ${
              status === 'O' ? 'open' : status === 'P' ? 'pending' : 'closed'
            }`}
          >
            {status === 'O' ? 'Open' : status === 'P' ? 'Pending' : 'Closed'}
          </span>
        </div>
        <div className="case-status-content-container">
          <div className="case-statis-label-text"> Case Filled </div>
          <div className="case-filled-date">Jan 10, 2024</div>{' '}
        </div>
      </div>
    </>
  );
};

export default Case;
