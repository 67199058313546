import React from 'react';
import researchIcon from '@icons/research-icon.svg';
import compareIcon from '@icons/compare-icon.svg';
import analyzeIcon from '@icons/analyze-icon.svg';
import profileIcon from '@icons/profile-icon.svg';
import argumentIcon from '@icons/argument-icon.svg';
import trialIcon from '@icons/trial-icon.svg';
import atticusIcon from '@icons/atticus-chat-room-frame.svg';
import FeatureCard from '../Shared/FeatureCard/FeatureCard';
import './Welcome.css';
import Header from '../Shared/Header/Header';
import ChatInputBox from '../Shared/ChatInputBox/ChatInputBox';

const features = [
  {
    icon: researchIcon,
    title: 'Research',
    description:
      'Ask general questions about the Constitution, United States Code, or case law.',
    workflow: 'research',
  },
  {
    icon: compareIcon,
    title: 'Compare',
    description: 'Build a table demonstrating the differences in documents.',
    workflow: 'compare',
  },
  {
    icon: analyzeIcon,
    title: 'Analyze',
    description: 'Discovery, case documents, legal arguments, and more.',
    workflow: 'analyze',
  },
  {
    icon: profileIcon,
    title: 'Profile',
    description: 'Develop a profile of a party in the case.',
    workflow: 'profile',
  },
  {
    icon: argumentIcon,
    title: 'Argument',
    description:
      'Develop logic and support for a claim or refute the prosecution.',
    workflow: 'argument',
  },
  {
    icon: trialIcon,
    title: 'Trial',
    description:
      'Craft interrogatories, a trial strategy, and witness preparation.',
    workflow: 'trial',
  },
];

const Welcome = () => {
  return (
    <section className="chat-welcome-section">
      <Header />
      <div className="chat-welcome-container">
        <div className="chat-welcome-content">
          <div className="chat-welcome-header">
            <img src={atticusIcon} alt="" />
            <h1>Chat with Atticus AI – Your Legal Ally</h1>
            <p>Enhance your criminal defense practice. Start chatting now!</p>
          </div>
          <div className="chat-welcome-features-grid">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                workflow={feature.workflow}
              />
            ))}
          </div>
        </div>
        <ChatInputBox />
      </div>
      {/* Add Footer Here if needed */}
    </section>
  );
};

export default Welcome;
