import React from "react";
import NavBar from "../../../Shared/NavBar/NavBar";
import Footer from "../../../Shared/Footer/Footer";
import { Outlet } from "react-router-dom";
import "./Layout.css";

const Layout = () => {
  return (
    <div className="home-layout-container">
      <div className="home-layout-content">
        <NavBar />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
