import React, { useContext } from 'react';
import Button from '@Shared/Button/Button';
import GenerateMotionContext from './GenerateMotionContext';

const MotionTypeButtons = () => {
  const { selectedMotion, setSelectedMotion } = useContext(
    GenerateMotionContext
  );

  const motionTypes = [
    {
      name: 'Motion to Extend Time',
      value: 'extendTime',
    },
    {
      name: 'Motion to Suppress Evidence',
      value: 'suppressEvidence',
    },
    {
      name: 'Motion to Dismiss Indictment',
      value: 'dismissIndictment',
    },
  ];

  const isActive = (typeToCheck) => {
    if (!selectedMotion) return false;
    return selectedMotion.value === typeToCheck.value;
  };

  return (
    <>
      {motionTypes.map((motionType) => (
        <Button
          onClick={() => setSelectedMotion(motionType)}
          className={`button--outline cursor-pointer recommendation-button ${
            isActive(motionType) ? 'isActive' : ''
          }`}
        >
          {motionType.name}
        </Button>
      ))}
    </>
  );
};

export default MotionTypeButtons;
