import React from "react";
import "./Button.css";

const Button = ({ children, style, onClick, className = "", type = "button" }) => {
  return (
    <button type={type} style={style} className={`common-button ${className} `} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;

/**
 * CSS Class Purposes:
 *
 * - No Class: Applies the base styles only, resulting in a button with default styling, displaying just text without additional border or color.
 *
 * - `button--primary`: Adds a gradient background with black text, typically used for main action buttons that need to stand out.
 *
 * - `button--secondary`: Provides a solid blue background with white text, often used for secondary actions or less prominent buttons.
 *
 * - `button--transparent`: Applies a transparent background with gray text and a gray border, suitable for buttons that should blend into the background.
 *
 * - `button--outline`: Features a transparent background with dark green text and a dark green border, giving an outlined appearance.
 *
 * - `button--square`: Removes the border radius to create a square-shaped button, which is used for a more angular design.
 *
 * - `[custom-class]`: Use to add any custom styles or override default button styles as needed.
 */
