const searchCases = [
{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "United States",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
"dateClosed": "06/02/2024",
"docketEntry": "203",
"docketNumber": "23-3190",


    },
    {
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "7 Dadon, David (dft)",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
 "dateFiled": "2023-10-18T00:00:00-07:00",
"dateClosed": "06/02/2024",
"docketEntry": "203",
"docketNumber": "23-3190",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "8 Dadon, David (dft) ",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "9 Dadon, David (dft) ",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "USA v. O'Connor",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "USA v. Martinez",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
"docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "USA v. Johnson",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "United States v. Donald Trump",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
"docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "USA v. Smith",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "United States v. Donald Trump",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
 "docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
},{
"absolute_url": "/docket/67891889/01208567646/2/united-states-v-donald-trump/",
"caseName": "United States v. Donald Trump",
"court": "Court of Appeals for the D.C. Circuit",
"court_citation_string": "D.C. Cir.",
"dateFiled": "2023-10-18T00:00:00-07:00",
"docketNumber": "23-3190",
"dateClosed": "06/02/2024",
"docketEntry": "203",
}
]


export default searchCases