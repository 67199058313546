import React, { useContext, useState } from 'react';
import ChatContext from './ChatContext';

const ChatProvider = ({ children }) => {

  const mockMessages = [
    {
      id: 1,
      content:
        'What are some common defenses used in cases involving intellectual property infringement?',
      userIsAuthor: true,
      dateCreated: '2021-09-01T12:00:00Z',
    },
    {
      id: 2,
      content:
        "In cases of intellectual property infringement, common defenses include fair use, lack of substantial similarity, and invalidity of the asserted intellectual property rights. Additionally, defendants may argue that the alleged infringement was unintentional or that the plaintiff's rights are not valid or enforceable",
      userIsAuthor: false,
      dateCreated: '2021-09-01T12:01:00Z',
    },
  ];

  const [messages, setMessages] = useState(mockMessages);
  const [workflow, setWorkflow] = useState(null);
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  const addRequestMessage = (message) => {
    setMessages((prevMessages) => {
      return [...prevMessages, {
        id: prevMessages.length + 1,
        content: message,
        userIsAuthor: true,
        dateCreated: new Date().toISOString(),
      }];
    });
  }

  const addResponseMessage = (message) => {
    setMessages((prevMessages) => {
      return [...prevMessages, {
        id: prevMessages.length + 1,
        content: message,
        userIsAuthor: false,
        dateCreated: new Date().toISOString(),
        speed: 50,
      }];
    });
  }

  return (
    <ChatContext.Provider value={{
      messages,
      setMessages,
      workflow,
      setWorkflow,
      waitingForResponse,
      setWaitingForResponse,
      addRequestMessage,
      addResponseMessage,
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
