import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logIn } from '@state/actions/authActions';
import { ReactComponent as DropdownArrowIcon } from '@icons/arrow-dropdown.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import NavBar from '../Shared/NavBar/NavBar';
import './Layout.css';
import useRedirectIfNeeded from '../../../../../hooks/useRedirectIfNeeded';
import Motions from '../Cases/CasesShow/CaseOverview/Motions/Motions';
// import Motions from '../Cases/CasesIndex/CaseOverview/Motions/Motion';

const Layout = () => {
  const [selectedCase, setSelectedCase] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const dropdownOptions = [
    {
      text: 'Federal Criminal',
      link: '#',
    },
    {
      text: 'Option Two',
      link: '#',
    },
    {
      text: 'Option Three',
      link: '#',
    },
  ];

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  useRedirectIfNeeded(selectedCase, setSelectedCase);
  const isShowMotions =
    pathname.includes('/overview') || pathname.includes('/timeline');

  console.log({ isShowMotions });
  // Get values from redux store if they are not already present
  useEffect(() => {
    if (!user) {
      dispatch(logIn('test'));
    }
  }, [user]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-flex-wrapper">
      <NavBar selectedCase={selectedCase} />
      <div className="dashboard-main">
        <div className="dashboard-header">
          {selectedCase ? (
            <div className="case-content-container">
              <p className="case-name">
                {selectedCase?.caseDetails?.caseTitle}
              </p>{' '}
              <p className="criminal-no">Criminal No. 1:23-cr-00257-TSC</p>
            </div>
          ) : (
            <>
              {' '}
              <p className="dashboard-header-main-text">
                Welcome to Atticus AI!
              </p>
              <p className="dashboard-header-sub-text">
                Selected practice area:
              </p>
              <Dropdown className="dashboard-header-dropdown">
                <Dropdown.Toggle variant="" id="header-dropdown">
                  <p className="header-dropdown-toggle-text">
                    Federal Criminal
                  </p>
                  <DropdownArrowIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownOptions.map((option, index) => (
                    <Dropdown.Item key={index}>
                      <Link to={option.link}>{option.text}</Link>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <div className="dashboard-header-user-image-container">
                <img
                  src={user.profileImage}
                  alt="user profile picture thumbnail"
                />
              </div>
            </>
          )}
        </div>
        <main className="dashboard-content">
          <Outlet context={{ setSelectedCase }} />
        </main>
      </div>
      {isShowMotions && <Motions />}
    </div>
  );
};

export default Layout;
