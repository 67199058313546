import React from 'react';
import backIcon from '@icons/exit-arrow-top.svg';
import "./Header.css"


const Header = () => {
  return (
     <div className="chat-welcome-header-container">
        <h3>Atticus v 0.1</h3>
        <img src={backIcon} alt="" />
    </div>
  );
};

export default Header;