const cases = [
  {
    caseId: '02ca-0:2008cr05968',
    status: 'open',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. O'Connor",
      courtId: '02ca',
      caseLink: '0:2008cr05968',
      caseNumberFull: '5968',
      caseNumber: '5968-1234',
      dateFiled: '2088-12-04',
      effectiveDateClosed: '2011-06-16'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],

    legalTeamId: '101'
  },
  {
    caseId: '02ca-1:2008cr05968',
    status: 'closed',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Martinez",
      courtId: '02ca',
      caseLink: '1:2008cr05968',
      caseNumberFull: '5969',
      caseNumber: '5969-1234',
      dateFiled: '2088-12-05',
      effectiveDateClosed: '2011-06-17'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements", action_type: "Summary" }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion to Suppress Post Arrest Statements", action_type: "Summary", }, { text: 'Motion to Compel Discovery', action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      }
    ],
    legalTeamId: '102'
  },
  {
    caseId: '02ca-2:2008cr05968',
    status: 'open',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Johnson",
      courtId: '02ca',
      caseLink: '2:2008cr05968',
      caseNumberFull: '5970',
      caseNumber: '5970-1234',
      dateFiled: '2088-12-06',
      effectiveDateClosed: '2011-06-18'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '103'
  },
  {
    caseId: '02ca-3:2008cr05968',
    status: 'open',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Smith",
      courtId: '02ca',
      caseLink: '3:2008cr05968',
      caseNumberFull: '5971',
      caseNumber: '5971-1234',
      dateFiled: '2088-12-07',
      effectiveDateClosed: '2011-06-19'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '104'
  },
  {
    caseId: '02ca-4:2008cr05968',
    status: 'closed',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Brown",
      courtId: '02ca',
      caseLink: '4:2008cr05968',
      caseNumberFull: '5972',
      caseNumber: '5972-1234',
      dateFiled: '2088-12-08',
      effectiveDateClosed: '2011-06-20'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '105'
  },
  {
    caseId: '02ca-5:2008cr05968',
    status: 'open',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Lee",
      courtId: '02ca',
      caseLink: '5:2008cr05968',
      caseNumberFull: '5973',
      caseNumber: '5973-1234',
      dateFiled: '2088-12-09',
      effectiveDateClosed: '2011-06-21'
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '106'
  },
  {
    caseId: '02ca-6:2008cr05968',
    status: 'open',
    appId: '1',
    caseDetails: {
      caseTitle: "USA v. Wilson",
      courtId: '02ca',
      caseLink: '6:2008cr05968',
      caseNumberFull: '5974',
      caseNumber: '5974-1234',
      dateFiled: '208',
      effectiveDateClosed: '2011-06-22',
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '107'
  },
  {
    caseId: '0231-6:2008cr05968',
    status: 'closed',
    appId: '1',
    caseDetails: {
      caseTitle: "RUS v. Wilson",
      courtId: '02dw',
      caseLink: '6:2008cr05968',
      caseNumberFull: '5974',
      caseNumber: '5974-1234',
      dateFiled: '208',
      effectiveDateClosed: '2011-06-22',
    },
    timelineData: [
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Motion for Detention", action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: false }],

      },
      {
        date: '10 Apr, 2024',
        role: 'Defense Attorney',
        documents: [{ text: "Motion to Suppress Post Arrest Statements ", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }, { text: 'Motion to Compel Discovery', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", file_upload_status: true }],

      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '20 Apr, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }, { text: 'Criminal Complaint', action_type: "Summary", summary: "The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.", }],
      },
      {
        date: '10 Apr, 2024',
        role: 'Judge Decision',
        documents: [{ text: "Any motion pertaining to the oral argument in this case shall be filed on or before Monday, April 8, 2024.", action_type: "Summary" }],
      },
      {
        date: '5 March, 2024',
        role: 'Prosecutor',
        documents: [{ text: "Statement of Facts", action_type: "Summarize", }, { text: 'Criminal Complaint', action_type: "Summary" }],
      },
    ],
    legalTeamId: '107'
  },
]


export default cases;

