import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from '@Shared/ButtonPrimary/ButtonPrimary';
import JuristLogo from '../../../../../../assets/images/juristAi/jurist-logo.png';
import AtticusLogo from '../../../../../../assets/images/atticus/logo/atticus-logo.png';
import { ReactComponent as HomeIcon } from '@icons/home.svg';
import { ReactComponent as TeamLargeIcon } from '@icons/team-large.svg';
import { ReactComponent as CasesIcon } from '@icons/folder-open.svg';
import { ReactComponent as AccountIcon } from '@icons/profile.svg';
import { ReactComponent as SubscriptionsIcon } from '@icons/cash.svg';
import { ReactComponent as OrganizationIcon } from '@icons/team-small.svg';
import { ReactComponent as AskIcon } from '@icons/chat-bubble.svg';
import NavBarListItem from '../../../../../Shared/Layout/NavBarListItem/NavBarListItem';
import './NavBar.css';
import { ReactComponent as CaseOverviewIcon } from '@icons/home.svg';
import { ReactComponent as CaseTimelineIcon } from '@icons/team-large.svg';
import { ReactComponent as UploadDocumentIcon } from '@icons/folder-open.svg';

// TODO: Fix OrganizationIcon's bold stroke issue

const NavBar = ({ selectedCase }) => {
  return (
    <div className="dashboard-navigation">
      <div className="dashboard-navigation-logo-container">
        <img src={JuristLogo} className="dashboard-nav-logo" alt="logo" />
        <img src={AtticusLogo} className="dashboard-nav-logo" alt="logo" />
        <div className="dashboard-navigation-text-logo-container">
          <p>Atticus</p>
          <div className="dashboard-navigation-text-logo-container-gradient-box">
            <p>Generative AI Law Briefs</p>
          </div>
        </div>
      </div>
      <ul className="dashboard-navigation-list-items">
        <NavBarListItem icon={HomeIcon} text="Home" url="/dashboard" />
        <NavBarListItem
          icon={TeamLargeIcon}
          text="Your Legal Team"
          url="/dashboard/team"
        />
        <NavBarListItem icon={CasesIcon} text="Cases" url="/dashboard/cases/" />
        <NavBarListItem
          icon={AccountIcon}
          text="Account Options"
          url="/dashboard/account/"
        />
        <li className="dashboard-navigation-special-link">
          <Link to="#">Select practice area</Link>
        </li>
        <NavBarListItem
          icon={SubscriptionsIcon}
          text="Manage Subscriptions"
          url="/dashboard/subscriptions/"
        />
        <NavBarListItem
          icon={OrganizationIcon}
          text="Manage Organization"
          url="/dashboard/organization/"
        />
        {selectedCase && (
          <>
            <h6 className="navigation-case-title">
              {selectedCase?.caseDetails?.caseTitle}
            </h6>
            <ul className="dashboard-navigation-list-items">
              <NavBarListItem
                icon={CaseOverviewIcon}
                text="Case Overview"
                url={`/dashboard/cases/${selectedCase.caseId}/overview`}
              />
              <NavBarListItem
                icon={CaseTimelineIcon}
                text="Case Timeline"
                url={`/dashboard/cases/${selectedCase.caseId}/timeline`}
              />
              <NavBarListItem
                icon={UploadDocumentIcon}
                text="Upload Documents"
                url={`/dashboard/cases/${selectedCase.caseId}/upload`}
              />
            </ul>
          </>
        )}
      </ul>

      <ButtonPrimary style={{ width: '90%' }}>
        <AskIcon />
        Ask Atticus
      </ButtonPrimary>
    </div>
  );
};

export default NavBar;
