/* eslint-disable no-constant-condition */
import axios from 'axios';
import { createPacerData } from '../../graphql4/mutations';
import cases from '../mocks/cases';
import { generateClient } from './api-client'; // Ensure the path is correct

const BASE_URL = 'https://juristaiapi.wrmlabs.com/user/search';

// Search by ID function
export const searchById = async (id, token) => {
    // TODO: Remove this mock data when the API is ready
    if (false) { // Change to `false` to bypass mock data when the API is ready
        return [cases[0]];
    }

    try {
        const response = await axios.get(`${BASE_URL}?id=${id}&token=${token}`, {
            maxRedirects: 5,
        });
        const data = await response.data;
        console.log(data);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

// Advanced search function
export const advancedSearch = async ({
    effectiveDateClosedFrom,
    effectiveDateClosedTo,
    caseNumber,
    caseTitle,
    dateFiledFrom,
    dateFiledTo,
    lastName,
    firstName,
    token,
}) => {
    // TODO: Remove this mock data when the API is ready
    if (true) { // Change to `false` when ready to use actual API
        return cases;
    }

    // URL for advanced search
    const URL = `https://juristaiapi.wrmlabs.com/api/adv/search?token=${token}`;

    const headers = {
        'Content-Type': 'application/json',
    };

    const body = {
        effectiveDateClosedFrom,
        effectiveDateClosedTo,
        caseNumber,
        caseTitle,
        dateFiledFrom,
        dateFiledTo,
        party: {
            lastName,
            firstName,
        },
    };

    try {
        const response = await axios.post(URL, body, { headers });
        const data = await response.data;
        console.log(data);
        return data;
    } catch (error) {
        console.error(error);
        return error;
    }
};

// Add case to PACER
export const addCase = async ({
    courtId,
    caseId,
    caseYear,
    caseNumber,
    caseOffice,
    caseType,
    caseTitle,
    dateFiled,
    dateTermed,
    dateDischarged,
    bankruptcyChapter,
    dispositionMethod,
    jointBankruptcyFlag,
    jurisdictionType,
    effectiveDateClosed,
    caseLink,
    caseNumberFull,
}) => {
    try {
        const client = generateClient(); // Ensure this function is correctly defined and imported
        const newPacerData = await client.graphql({
            query: createPacerData,
            variables: {
                input: {
                    courtId,
                    caseId,
                    caseYear,
                    caseNumber,
                    caseOffice,
                    caseType,
                    caseTitle,
                    dateFiled,
                    dateTermed,
                    dateDischarged,
                    bankruptcyChapter,
                    dispositionMethod,
                    jointBankruptcyFlag,
                    jurisdictionType,
                    effectiveDateClosed,
                    caseLink,
                    caseNumberFull,
                    "status": true,
                },
            },
        });

        console.log('newPacerData', newPacerData);
        return newPacerData;
    } catch (error) {
        console.log(error);
        return error;
    }
};
