import React from 'react';
import { Route, Routes } from 'react-router-dom';

// import JuristAiDashboard from '../components/Dashboard/JuristAi/Welcome/Welcome';
import AtticusDashboard from '@pages/Dashboard/Atticus/Atticus/Atticus';
import CasesIndex from '@pages/Dashboard/Atticus/Cases/CasesIndex/CasesIndex';
import CasesSearch from '@pages/Dashboard/Atticus/Cases/CasesSearch/CasesSearch';
import LegalTeamsIndex from '@pages/Dashboard/Atticus/LegalTeams/LegalTeamsIndex/LegalTeamsIndex';
import DashboardLayout from '@pages/Dashboard/Atticus/_Layout/Layout';
import Home from '@pages/Home/Home/Home';
import Layout from '@pages/Home/_Layout/Layout';
import LegalTeamMemberProfile from '@pages/Dashboard/Atticus/LegalTeams/LegalTeamsIndex/LegalTeamMemberProfile/LegalTeamMemberProfile';
import CaseOverview from '@pages/Dashboard/Atticus/Cases/CasesShow/CaseOverview/CaseOverview.jsx';
import UploadDocuments from '@pages/Dashboard/Atticus/Cases/CasesShow/UploadDocuments/UploadDocuments.jsx';
import CaseTimeline from '@pages/Dashboard/Atticus/Cases/CasesShow/CaseTimeline/CaseTimeline.jsx';
import ManageOrganization from '@pages/Dashboard/Atticus/ManageOrganization/ManageOrganization/ManageOrganization.jsx';
import ChatRoomLayout from '@pages/Dashboard/Chat/_Layout/Layout.jsx';
import Welcome from '@pages/Dashboard/Chat/Welcome/Welcome';
import Research from '@pages/Dashboard/Chat/Research/Research';

function AllRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/dashboard/" element={<DashboardLayout />}>
          <Route index element={<AtticusDashboard />} />
          <Route path="cases/">
            <Route index element={<CasesIndex />} />
            <Route path="search/" element={<CasesSearch />} />
            <Route path=":id/overview" element={<CaseOverview />} />
            <Route path=":id/upload" element={<UploadDocuments />} />
            <Route path=":id/timeline" element={<CaseTimeline />} />
          </Route>
          <Route path="team/" element={<LegalTeamsIndex />} />
          <Route
            path="team-member/:userId"
            element={<LegalTeamMemberProfile />}
          />
          <Route path="organization" element={<ManageOrganization />} />
        </Route>

        <Route path="/chat/" element={<ChatRoomLayout />}>
          <Route index element={<Welcome />} />
          <Route path="research" element={<Research />} />
        </Route>
      </Routes>
    </>
  );
}

export default AllRouter;
