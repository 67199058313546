import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TimelineItem from './TimelineItem/TimelineItem';
import './CaseTimeline.css';
import Dropdown from '@Shared/Dropdown/Dropdown';
import arrowUp from '@icons/arrow-up-filled-black.svg';
import arrowDown from '@icons/arrow-down-filled-black.svg';
import cases from '@lib/mocks/cases';

const FILTER_TYPES = ['Newest to Oldest', 'Oldest to Newest'];

const CaseTimeline = () => {
  const { id } = useParams();
  const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const caseData = cases?.find((caseItem) => caseItem?.caseId === id);
  const timelineData = caseData ? caseData?.timelineData : [];

  const handleFunctionSelect = (func) => {
    setSelectedFilter(func);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    if (currentIndex < timelineData?.length - 3) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <main className="case-timeline-page">
      <div className="case-timeline-page-dropdown">
        <div className="case-timeline-page-dropdown-content">
          <Dropdown
            options={FILTER_TYPES}
            selectedOption={selectedFilter}
            onOptionSelect={handleFunctionSelect}
            placeholder="Select Function"
            isFilter
          />
        </div>
      </div>
      <div className="timeline-container">
        {caseData.timelineData?.length > 0 ? (
          <>
            <img
              src={arrowUp}
              alt="Arrow Up"
              className="case-timeline-filled-arrow-up"
              onClick={handlePrevious}
              style={{
                opacity: currentIndex > 0 ? 1 : 0.5,
                cursor: currentIndex > 0 ? 'pointer' : 'not-allowed',
              }}
            />
            <div className="timeline-line"></div>
            <div className="timeline-items">
              {timelineData
                ?.slice(currentIndex, currentIndex + 3)
                ?.map((item, index) => (
                  <TimelineItem
                    key={index + currentIndex}
                    date={item?.date}
                    role={item?.role}
                    documents={item?.documents}
                  />
                ))}
            </div>
            <img
              src={arrowDown}
              alt="Arrow Down"
              className="case-timeline-filled-arrow-down"
              onClick={handleNext}
              style={{
                opacity: currentIndex < timelineData?.length - 3 ? 1 : 0.5,
                cursor:
                  currentIndex < timelineData?.length - 3
                    ? 'pointer'
                    : 'not-allowed',
              }}
            />
          </>
        ) : (
          <div className="no-data-message">No timeline data available.</div>
        )}
      </div>
    </main>
  );
};

export default CaseTimeline;
