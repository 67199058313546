import { userTypes } from '../types';

const userReducer = (state = null, action) => {
  switch (action.type) {
    case userTypes.CREATE_ONE:
      return action.payload;
    case userTypes.GET_ONE:
    case userTypes.SET_USER:
      return action.payload;
    case userTypes.UPDATE_ONE:
      return action.payload;
    default:
      return state;
  }
}

export default userReducer;
