import React from 'react';
import DashboardPannel from './DashboardPannel';
import BadgeInfo from '../../../../../Shared/BadgeInfo/BadgeInfo';
import { titleToAbbreviation } from '../../../../../../lib/utils/caseHelpers';
import { Link } from 'react-router-dom';

const CasesPannel = ({ cases, numberOfCasesToDisplay, showingOpenCases }) => {
  return (
    <DashboardPannel title={`${showingOpenCases ? 'Open' : 'Closed'} Cases`} subtitle={`${cases.length} cases`} linkText="See Cases" linkAnchor="cases/open">
    <div className="dashboard-pannel-badge-container">
    {cases && cases.map((c, index) => {
      if (index < 2) {
        return (
          <Link key={c.id} to={`/cases/${c.id}`}>
            <BadgeInfo key={c.id} className={`${showingOpenCases ? '131321' : 'closed'}`}>{titleToAbbreviation(c.caseDetails.caseTitle)}</BadgeInfo>
          </Link>
        )
      } else if (index === numberOfCasesToDisplay) {
        const remainingCases = cases.length - numberOfCasesToDisplay;
        return <BadgeInfo key={c.id} hasAdditionalItems={true}>+{remainingCases}</BadgeInfo>; // Render a different element for the third item
      } else {
        return null; // Do not render anything for indices greater than 2
      }
    })}


    </div>
    </DashboardPannel>
  );
}

export default CasesPannel;
