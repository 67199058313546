import React from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import ChatProvider from '../ChatProvider';

const Layout = () => {
  return (
    <ChatProvider>
      <div className="chatroom-layout-container">
        <div className="chatroom-left-sidebar">
          {/* left side bar content here  */}
        </div>
        <div className="chatroom-content-container">
          <Outlet />
        </div>
        <div className="chatroom-right-sidebar">
          {/* chat history content here  */}
        </div>
      </div>
    </ChatProvider>
  );
};

export default Layout;
