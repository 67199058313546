import React, { useState, useContext } from 'react';
import './Motions.css';
import Button from '@Shared/Button/Button';
import MotionOptionsSelect from './MotionOptionsSelect';
import { ReactComponent as DownloadFile } from '@icons/download-file.svg';
import GenerateMotionModal from './GenerateMotionModal/GenerateMotionModal';
import GenerateMotionProvider from './GenerateMotionProvider';
import GenerateMotionButton from './GenerateMotionButton';
import MotionTypeButtons from './MotionTypeButtons';

const Motions = () => {
  const recentMotions = [
    { name: 'Motion to Extend Time' },
    { name: 'Motion to Suppress Evidence' },
  ];

  return (
    <GenerateMotionProvider>
      <div className="motion-container">
        <h3 className="cases-motion-title">Motions</h3>
        <div className="motion-recommendations-container">
          <h4>Atticus Recommends</h4>
          <MotionTypeButtons />
          <MotionOptionsSelect />
          <GenerateMotionButton />
        </div>
        <div className="recent-motions-container">
          <h4>Recently Used Motions</h4>
          {recentMotions.map((motion) => (
            <div className="recent-motion">
              <DownloadFile /> {motion.name}
            </div>
          ))}
        </div>
        <GenerateMotionModal />
      </div>
    </GenerateMotionProvider>
  );
};

export default Motions;
