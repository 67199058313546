import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DashboardPannel from './DashboardPannel/DashboardPannel';
import UserHeadshotContainer from '../Shared/UserHeadshotContainer/UserHeadshotContainer';
import PageTitle from '../Shared/PageTitle/PageTitle';
import CasesPannel from './DashboardPannel/CasesPannel';
import SearchSection from './SearchSection/SearchSection';
import { getLegalTeam } from '@lib/apis/resources/legalTeam';
import { getCases } from '@lib/apis/resources/case';
import { logIn } from '@state/actions/authActions';
import {
  getOpenCases,
  getClosedCases,
  titleToAbbreviation,
} from '@lib/utils/caseHelpers';
import './Atticus.css';
import { Page } from 'openai/pagination.mjs';

const Atticus = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const NUMBER_OF_CASES_TO_DISPLAY = 2;

  const [cases, setCases] = useState(null);
  const [legalTeam, setLegalTeam] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          // TODO: Redirect to login
          console.log('go to login to set user');
          dispatch(logIn('test'));
          return;
        }

        const userId = 'user.id';
        const token = 'token';

        const [casesResponse, legalTeamResponse] = await Promise.all([
          getCases(userId, token),
          getLegalTeam(userId, token),
        ]);

        setCases(casesResponse);
        setLegalTeam(legalTeamResponse);
        console.log('cases:', casesResponse);
      } catch (error) {
        console.error('Error getting cases and legal team:', error);
      }
    };

    fetchData();
  }, [user, dispatch]);

  const pannelData = [
    {
      title: 'Your legal team',
      subtitle: '10 members',
      link: 'Manage Team',
    },
    {
      title: 'Open cases',
      subtitle: '5 cases',
      link: 'See Cases',
    },
    {
      title: 'Closed cases',
      subtitle: '10 cases',
      link: 'See Cases',
    },
  ];

  const userInfo = [
    { src: 'https://picsum.photos/200', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/100', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/200', alt: 'User headshot', link: 'team' },
    { src: 'https://picsum.photos/100', alt: 'User headshot', link: 'team' },
  ];

  if (!cases || !legalTeam) {
    return <div>Loading...</div>;
  }

  return (
    <main className="dashboard-page main-page">
      <PageTitle title="Federal Criminal" subtitle="Overview" />
      <div className="dashboard-pannel-container">
        <DashboardPannel
          title="Your Legal Team"
          subtitle={`${legalTeam.userIds.length} members`}
          linkText="Manage Team"
          linkAnchor="team"
        >
          <UserHeadshotContainer userArray={userInfo} />
        </DashboardPannel>
        <CasesPannel
          showingOpenCases={true}
          cases={getOpenCases(cases)}
          numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY}
        />
        <CasesPannel
          showingOpenCases={false}
          cases={getClosedCases(cases)}
          numberOfCasesToDisplay={NUMBER_OF_CASES_TO_DISPLAY}
        />
      </div>
      <SearchSection />
    </main>
  );
};

export default Atticus;
