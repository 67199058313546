import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import PacerSearchContainer from '@Shared/PacerSearch/PacerSearchContainer';
import PacerSearchSubmitButton from '@Shared/PacerSearch/PacerSearchSubmitButton';
import { advancedSearch } from '@lib/apis/pacer-api';
import { ReactComponent as Plus } from '@icons/plus-black.svg';
import PacerSearchTextInput from '@Shared/PacerSearch/PacerSearchTextInput';
import MyCasesTable from './MyCasesTable/MyCasesTable';
import './CasesIndex.css';
import { useOutletContext } from 'react-router-dom';
import Drawer from '@Shared/Drawer/Drawer';
import AddNewCase from './AddNewCase/AddNewCase';

const CasesIndex = () => {
  const navigate = useNavigate();
  const { setSelectedCase } = useOutletContext();

  const [cases, setCases] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      // Search for all cases that belong to the user saved in redux store
      const data = await advancedSearch('');
      setCases(data);
    };

    fetchData();
  }, []);

  const handleFormDataChange = (data) => {
    setFormData(data);
  };

  const searchPacer = () => {
    navigate('/dashboard/cases/search', {
      state: {
        query: formData?.searchQuery,
      },
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchPacer();
    }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <main className="dashboard-page cases-page">
      <PageTitle title="Federal Criminal" subtitle="Cases" />
      <div className="cases-index-search-form-container">
        <div onKeyDown={handleKeyDown}>
          <PacerSearchContainer onFormDataChange={handleFormDataChange}>
            <PacerSearchTextInput
              placeholder="Search pacer..."
              name="searchQuery"
            />
            <PacerSearchSubmitButton onClick={toggleDrawer}>
              <Plus />
              <p>Add a New Criminal Case</p>
            </PacerSearchSubmitButton>
          </PacerSearchContainer>
        </div>
        <MyCasesTable cases={cases} onSelectCase={setSelectedCase} />
      </div>
      <Drawer
        title={'New Criminal case'}
        isOpen={openDrawer}
        onClose={toggleDrawer}
      >
        <AddNewCase onClose={toggleDrawer} />
      </Drawer>
    </main>
  );
};

export default CasesIndex;
